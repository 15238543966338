// Source: https://github.com/moment/luxon/blob/master/src/impl/formats.js

import { Intl } from "temporal-polyfill";

const n = "numeric";
const s = "short";
const l = "long";

// مثال: شهریور ۱۴۰۱
// Example: Aug 2022
const DATE_YEAR_MONTH: Intl.DateTimeFormatOptions = {
  month: s,
  year: n,
};

// مثال: ۱۴۰۱/۶/۲۸
// Example: 8/31/2022
const DATE_SHORT: Intl.DateTimeFormatOptions = {
  year: n,
  month: n,
  day: n,
};

// مثال: ۲۸ شهریور ۱۴۰۱
// Example: Aug 31, 2022
const DATE_MED: Intl.DateTimeFormatOptions = {
  year: n,
  month: s,
  day: n,
};

// مثال: ۲۸ شهریور
// Example: Aug 31
const DATE_MED_WITHOUT_YEAR: Intl.DateTimeFormatOptions = {
  month: s,
  day: n,
};

// مثال: سه‌شنبه ۸ شهریور ۱۴۰۱
// Example: Wed Aug 31, 2022
const DATE_MED_WITH_WEEKDAY: Intl.DateTimeFormatOptions = {
  year: n,
  month: s,
  day: n,
  weekday: s,
};

// مثال: سه‌شنبه
// Example: Wed
const DATE_WEEKDAY: Intl.DateTimeFormatOptions = {
  weekday: s,
};

// مثال: ۲۳:۴۵
// Example: 11:45 PM
const TIME_SIMPLE: Intl.DateTimeFormatOptions = {
  hour: n,
  minute: n,
};

// مثال: ۲۳:۴۵:۳۷
// Example: 11:45:37 PM
const TIME_WITH_SECONDS: Intl.DateTimeFormatOptions = {
  hour: n,
  minute: n,
  second: n,
};

// مثال: ۲۳:۴۵:۳۷ (‎+۴:۳۰ گرینویچ)
// Example: 11:45:37 PM GMT+4:30
const TIME_WITH_OFFSET: Intl.DateTimeFormatOptions = {
  hour: n,
  minute: n,
  second: n,
  timeZoneName: s,
};

// مثال: ۱۴۰۱/۶/۹،‏ ۲۳:۴۵
// Example: 8/31/2022, 11:45 PM
const DATETIME_SHORT: Intl.DateTimeFormatOptions = {
  year: n,
  month: n,
  day: n,
  hour: n,
  minute: n,
};

// مثال: ۱۴۰۱/۶/۹،‏ ۲۳:۴۵:۳۷
// Example: 8/31/2022, 11:45:37 PM
const DATETIME_SHORT_WITH_SECONDS: Intl.DateTimeFormatOptions = {
  year: n,
  month: n,
  day: n,
  hour: n,
  minute: n,
  second: n,
};

// مثال: ۹ شهریور ۱۴۰۱،‏ ۲۳:۴۵
// Example: Aug 31, 2022, 11:45 PM
const DATETIME_MED: Intl.DateTimeFormatOptions = {
  year: n,
  month: s,
  day: n,
  hour: n,
  minute: n,
};

// مثال: ۹ شهریور ۱۴۰۱،‏ ۲۳:۴۵:۳۷
// Example: Aug 31, 2022, 11:45:37 PM
const DATETIME_MED_WITH_SECONDS: Intl.DateTimeFormatOptions = {
  year: n,
  month: s,
  day: n,
  hour: n,
  minute: n,
  second: n,
};

// مثال: چهارشنبه ۹ شهریور ۱۴۰۱،‏ ۲۳:۴۵
// Example: Wed, Aug 31, 2022, 11:45 PM
const DATETIME_MED_WITH_WEEKDAY: Intl.DateTimeFormatOptions = {
  year: n,
  month: s,
  day: n,
  weekday: s,
  hour: n,
  minute: n,
};

// مثال: ۹ شهریور ۱۴۰۱،‏ ۲۳:۴۵ (‎+۴:۳۰ گرینویچ)
// Example: August 31, 2022, 11:45 PM GMT+4:30
const DATETIME_FULL: Intl.DateTimeFormatOptions = {
  year: n,
  month: l,
  day: n,
  hour: n,
  minute: n,
  timeZoneName: s,
};

// مثال: ۹ شهریور ۱۴۰۱،‏ ۲۳:۴۵:۳۷ (‎+۴:۳۰ گرینویچ)
// Example: August 31, 2022, 11:45:37 PM GMT+4:30
const DATETIME_FULL_WITH_SECONDS: Intl.DateTimeFormatOptions = {
  year: n,
  month: l,
  day: n,
  hour: n,
  minute: n,
  second: n,
  timeZoneName: s,
};

export const DATETIME_FORMATS = {
  DATE_YEAR_MONTH,
  DATE_SHORT,
  DATE_MED,
  DATE_MED_WITHOUT_YEAR,
  DATE_MED_WITH_WEEKDAY,
  DATE_WEEKDAY,
  TIME_SIMPLE,
  TIME_WITH_SECONDS,
  TIME_WITH_OFFSET,
  DATETIME_SHORT,
  DATETIME_SHORT_WITH_SECONDS,
  DATETIME_MED,
  DATETIME_MED_WITH_SECONDS,
  DATETIME_MED_WITH_WEEKDAY,
  DATETIME_FULL,
  DATETIME_FULL_WITH_SECONDS,
  DATE_DEFAULT: DATE_MED,
  DATETIME_DEFAULT: DATETIME_MED,
  TIME_DEFAULT: TIME_SIMPLE,
};

export const a = 1;

import SibaqLogo from "@/modules/international/common/assets/images/sibaq/logo/logo-named-500x105.png";
import AkwadLogo from "@/modules/international/common/assets/images/akwad/logo/logo-dark-named-354x105.png";
import CodernLogo from "@/modules/international/common/assets/images/codern/logo/logo-named-500x105.png";

const env = process.env.NODE_ENV;
const SIBAQ_HOST = env === "development" ? "sibaq:8000" : "sibaq.org";
const CODERN_HOST = env === "development" ? "codern:8000" : "codern.org";
const AKWAD_HOST = env === "development" ? "akwad:8000" : "akwad.io";
const internationalHosts = [SIBAQ_HOST, CODERN_HOST, AKWAD_HOST];

export { internationalHosts, SIBAQ_HOST, CODERN_HOST, AKWAD_HOST };

export const BrandLogoMapped = {
  [SIBAQ_HOST]: SibaqLogo,
  [AKWAD_HOST]: AkwadLogo,
  [CODERN_HOST]: CodernLogo,
};

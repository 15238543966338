import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { theme as baseTheme } from "theme";

export const theme = extendTheme(
  {
    config: {
      initialColorMode: "system",
      useSystemColorMode: false,
    },
    styles: {
      global: (props) => ({
        body: {
          bg: mode("gray.50", "gray.900")(props),
          color: mode("gray.900", "gray.50")(props),
          ".signIn_modal_content": {
            bg: mode("gray.100", "gray.800"),
          },
        },
        "*": {
          scrollMarginTop: "100px",
        },
      }),
    },
  },

  baseTheme,
);

export const resetColorMode = () => {
  localStorage.removeItem("chakra-ui-color-mode");
};

import { ChakraProvider } from "@chakra-ui/react";
import { ReactNode } from "react";
import { gql } from "graphql-request";
import { InternationalSiteType } from "@/gql-types";
import { InternationalSiteProvider } from "../../common/contexts/InternationalSiteContext";
import { useInternationalTheme } from "../../common/hooks/useInternationalTheme";
import { DeepPartial } from "@/common/types/util-types";

interface InternationalLayoutProps {
  children: ReactNode;
  internationalSite: DeepPartial<InternationalSiteType>;
}

export const InternationalLayout = ({ children, internationalSite }: InternationalLayoutProps) => {
  const theme = useInternationalTheme(internationalSite.site.domain);

  return (
    <InternationalSiteProvider internationalSite={internationalSite}>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </InternationalSiteProvider>
  );
};

InternationalLayout.fragments = {
  internationalSite: gql`
    fragment InternationalSiteFragment on InternationalSiteType {
      language
      brand_logo
      id
      site {
        name
        domain
        id
      }
      event {
        social_links {
          id
          type
          url
        }
      }
    }
  `,
};

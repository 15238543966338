import { createContext, ReactNode } from "react";
import { InternationalSiteType } from "@/gql-types";
import { DeepPartial } from "@/common/types/util-types";

export const InternationalSiteContext = createContext<DeepPartial<InternationalSiteType> | null>(null);

interface InternationalSiteProviderProps {
  internationalSite: DeepPartial<InternationalSiteType>;
  children: ReactNode;
}

export const InternationalSiteProvider = ({ children, internationalSite }: InternationalSiteProviderProps) => (
  <InternationalSiteContext.Provider value={internationalSite}>{children}</InternationalSiteContext.Provider>
);

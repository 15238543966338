import axios from "axios";
import { stringify } from "qs";
import { getBrowserCookie } from "@/common/utils/cookie";

export const axiosClient = axios.create({
  // baseURL: "http://localhost:8000",
  paramsSerializer: {
    serialize: (params) => stringify(params, { indices: false }),
  },
  headers: {
    "x-csrftoken": getBrowserCookie("csrf_token") || "",
  },
});

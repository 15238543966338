import { AKWAD_HOST, CODERN_HOST, SIBAQ_HOST } from "@/modules/international/common/constants";
import { internationalBaseTheme } from "../theme/base/index";
import { akwadTheme, codernTheme, sibaqTheme } from "@/modules/international/common/theme/index";
import { SiteType } from "@/gql-types";

const mappedThemes = {
  [SIBAQ_HOST]: sibaqTheme,
  [AKWAD_HOST]: akwadTheme,
  [CODERN_HOST]: codernTheme,
};

export const useInternationalTheme = (domain: SiteType["domain"]) => mappedThemes[domain] || internationalBaseTheme;

export const colors = {
  gray: {
    50: "#FAFAFA",
    100: "#F2F2F2",
    200: "#D9D9D9",
    300: "#D5D5D5",
    400: "#AEAEAE",
    500: "#808080",
    600: "#666666",
    700: "#373737",
    800: "#202020",
    900: "#191919",
  },
  brand: {
    50: "#D8FDF6",
    100: "#B0FBEC",
    200: "#60F7D8",
    300: "#00E0B5",
    400: "#008076",
    500: "#0A7267",
    600: "#006259",
    700: "#01463C",
    800: "#0A373B",
    900: "#132739",
  },
};

import { Intl, Temporal } from "temporal-polyfill";
import { isEqual } from "lodash";
import { DATETIME_FORMATS } from "./formats";

interface FormatDistanceOptions {
  addSuffix?: boolean;
}

/**
 * Intl.DurationFormat is not available yet. So we use our own implementation.
 * TODO: Use Temporal.Duration.toLocaleString as soon as Intl.DurationFormat is available.
 */
const formatDistance = (
  time: string | Temporal.Instant,
  baseTime: string | Temporal.Instant = undefined,
  options: FormatDistanceOptions = undefined,
) => {
  const { addSuffix } = options || { addSuffix: true };
  const baseInstant = baseTime ? Temporal.Instant.from(baseTime) : Temporal.Now.instant();
  const duration = Temporal.Instant.from(time).since(baseInstant);
  let suffix: string = "";
  if (addSuffix) suffix = duration.sign === -1 ? " پیش" : " دیگر";
  const seconds = Math.floor(Math.abs(duration.total("seconds")));

  if (seconds < 2 * 60)
    // 0-2 minutes
    return "اکنون";

  const minutes = Math.round(seconds / 60);
  if (minutes < 45)
    // 2 to 45 minutes
    return `${minutes.toLocaleString("fa-IR")} دقیقه${suffix}`;
  if (minutes < 90)
    // 45 minutes to 1.5 hours
    return `یک ساعت${suffix}`;

  const hours = Math.round(minutes / 60);
  if (hours < 24)
    // 2 - 23 hours
    return `${hours.toLocaleString("fa-IR")} ساعت${suffix}`;
  if (hours < 42)
    // 1 to 1.75 days
    return `یک روز${suffix}`;

  const days = Math.round(hours / 24);
  if (days < 30)
    // 2 - 29 days
    return `${days.toLocaleString("fa-IR")} روز${suffix}`;
  if (days <= 35) return `یک ماه${suffix}`;

  const months = Math.round(days / 30);
  if (months < 12)
    // 2 - 11 months
    return `${months.toLocaleString("fa-IR")} ماه${suffix}`;
  if (months < 5 * 12 && months % 12 !== 0)
    return `${Math.floor(months / 12).toLocaleString("fa-IR")} سال و ${(months % 12).toLocaleString(
      "fa-IR",
    )} ماه${suffix}`;
  return `${Math.round(months / 12).toLocaleString("fa-IR")} سال${suffix}`;
};

interface FormatDurationOptions {
  hideHoursAfterDays?: number;
  hideMinutesAfterHours?: number;
}

const formatDuration = (duration: Temporal.Duration, options?: FormatDurationOptions): string => {
  const { hideHoursAfterDays, hideMinutesAfterHours } = options || {};

  const totalMinutes = Math.floor(Math.abs(duration.total("minutes")));
  if (totalMinutes === 0) return "";

  const totalHours = Math.floor(totalMinutes / 60);
  const totalDays = Math.floor(totalHours / 24);

  const m = totalMinutes % 60;
  const h = totalHours % 24;
  const d = Math.floor(totalHours / 24);

  const list = [];
  if (d > 0) list.push(`${d.toLocaleString("fa-IR")} روز`);
  if (h > 0 && (!hideHoursAfterDays || hideHoursAfterDays >= totalDays)) list.push(`${h.toLocaleString("fa-IR")} ساعت`);
  if (m > 0 && (!hideMinutesAfterHours || hideMinutesAfterHours >= totalHours))
    list.push(`${m.toLocaleString("fa-IR")} دقیقه`);

  return list.join(" و ");
};

const formatTemporalObject = (
  time: Exclude<Intl.Formattable, Date>,
  fmt: Intl.DateTimeFormatOptions,
  locale: string,
) => {
  if (
    isEqual(fmt, DATETIME_FORMATS.DATE_MED_WITH_WEEKDAY) ||
    isEqual(fmt, DATETIME_FORMATS.DATETIME_MED_WITH_WEEKDAY)
  ) {
    // The result of native toLocaleString is not good with these two formats.
    // So we format weekday separately and concat with date.
    const { weekday, ...fmtWithoutWeekday } = fmt;
    return `${time.toLocaleString(locale, { weekday })} ${time.toLocaleString(locale, fmtWithoutWeekday)}`;
  }
  try {
    return time.toLocaleString(locale, fmt);
  } catch (err) {
    console.error(err);
    // TODO: remove this try/except after chrome/firefox timezone bug on MacOS Sonoma fixed
    return "";
  }
};

const format = (
  time: Exclude<Intl.Formattable, Date> | string,
  fmt: Intl.DateTimeFormatOptions = undefined,
  locale: string = "fa-IR",
): string => {
  if (typeof time === "undefined") return undefined;
  if (typeof time === "string") {
    // Expecting ISO8601 string
    try {
      return formatTemporalObject(Temporal.Instant.from(time), fmt || DATETIME_FORMATS.DATETIME_DEFAULT, locale);
    } catch (err) {
      return formatTemporalObject(Temporal.PlainDateTime.from(time), fmt || DATETIME_FORMATS.DATE_DEFAULT, locale);
    }
  }
  if (
    time instanceof Temporal.Instant ||
    time instanceof Temporal.ZonedDateTime ||
    time instanceof Temporal.PlainDateTime
  )
    return formatTemporalObject(time, fmt || DATETIME_FORMATS.DATETIME_DEFAULT, locale);
  if (time instanceof Temporal.PlainDate)
    return formatTemporalObject(time, fmt || DATETIME_FORMATS.DATE_DEFAULT, locale);
  if (time instanceof Temporal.PlainTime)
    return formatTemporalObject(time, fmt || DATETIME_FORMATS.TIME_DEFAULT, locale);
  return formatTemporalObject(time, fmt, locale);
};

export const DateTimeFormatter = {
  formatDistance,
  formatDuration,
  format,
  ...DATETIME_FORMATS,
};

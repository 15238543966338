import { extendTheme } from "@chakra-ui/react";
import type { ThemeConfig } from "@chakra-ui/react";
import { fonts } from "./foundations/fonts";
import { internationalBaseTheme } from "../base";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

export const codernTheme: ThemeConfig = extendTheme({ config, fonts }, internationalBaseTheme);

import { ComponentStyleConfig } from "@chakra-ui/react";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "4px",
    _hover: {
      textDecor: "none",
    },
  },
  sizes: {
    md: {
      height: "40px",
      fontSize: "md",
      fontWeight: "600",
    },
  },
  defaultProps: {
    colorScheme: "brand",
  },
};

import { extendTheme, theme as chakraTheme } from "@chakra-ui/react";
import type { ThemeConfig } from "@chakra-ui/react";
import { theme } from "@/modules/college/plp/theme/index";
import { fonts } from "@/modules/international/common/theme/akwad/foundations/fonts";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

export const akwadTheme: ThemeConfig = extendTheme(
  {
    config,
    fonts,
    components: {
      Modal: {
        baseStyle: {
          ...chakraTheme.components.Modal.baseStyle,
          dialog: {
            _dark: {
              bg: "gray.700",
            },
          },
        },
      },
      Menu: {
        baseStyle: {
          list: {
            _dark: {
              bg: "gray.700",
            },
          },
          item: {
            _dark: {
              bg: "gray.700",
              _hover: {
                bg: "gray.800",
              },
            },
          },
        },
      },
    },
  },
  theme,
);

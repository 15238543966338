import { ComponentStyleConfig } from "@chakra-ui/react";

export const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      fontWeight: "bold",
    },
  },
  sizes: {
    md: {
      field: {
        height: "48px",
        borderRadius: "4px",
      },
    },
  },
};

import { useBreakpointValue } from "@chakra-ui/react";
import { useGlobalServerValues } from "@/common/components/GlobalServerValuesProvider";

type useSSRBreakpointValueOptions = { ssr?: boolean };

export const useSSRBreakpointValue = <T>(values: T[], { ssr = true }: useSSRBreakpointValueOptions = {}): T => {
  const value = useBreakpointValue(values, { ssr });
  const { isMobileUA } = useGlobalServerValues();

  // At client side, useBreakpointValue returns "undefined" initially, and in next render, returns the correct value.
  // With this line, it works like server initially. So we avoid hydration errors like:
  //    Did not expect server HTML to contain a <div> in <div>
  if (typeof window === "undefined" || typeof value === "undefined") {
    // At server side, if mobile device detected, return the mobile version.
    if (isMobileUA) return values[0];
    // Otherwise, we assume we are on desktop.
    return values[values.length - 1];
  }

  return value;
};

import { CustomThemeTypings, extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import { theme as baseTheme } from "theme";
import { colors } from "./foundations/colors";
import { fonts } from "./foundations/fonts";
import { sizes } from "./foundations/sizes";
import { Button } from "./components/button";
import { Input } from "./components/input";
import { semanticTokens } from "./semantictokens";
import { styles } from "./styles";

export const internationalBaseTheme: CustomThemeTypings = extendTheme(
  {
    colors,
    semanticTokens,
    styles,
    fonts,
    sizes,
    components: {
      Button,
      Input,
    },
  },
  withDefaultColorScheme({ colorScheme: "brand" }),
  baseTheme,
);
